import Vue from 'vue';
import { MapRequestSearchField } from '@/app/shared/components/kl-search-maprequests/kl-search-maprequests';
import * as KlipApi from '@/api/klip-api.proxy';
import DateUtil from '@/app/shared/helpers/date-util';

// TODO: INVESTIGATE > NO LONGER USED??
// export class UnaFilter {
//     private _unaActiveTab: string;
//     private _pdaZoneActiveTab: string;
//     private _unaToReplyFilter: string;
//     private _unaRepliedFilter: MapRequestSearchField;
//     private _mriFilter: MapRequestSearchField;
//     private _currentMriPage: number = undefined;
//     private _unaToReplyPage: number = undefined;
//     private _unaRepliedPage: number = undefined;
//     private _unaToConfirmPage: number = undefined;
//
//     set unaActiveTab(tab: string) {
//         this._unaActiveTab = tab;
//     }
//
//     get unaActiveTab(): string {
//         return this._unaActiveTab ? this._unaActiveTab : '';
//     }
//
//     set pdaZoneActiveTab(tab: string) {
//         this._pdaZoneActiveTab = tab;
//     }
//
//     get pdaZoneActiveTab(): string {
//         return this._pdaZoneActiveTab ? this._pdaZoneActiveTab : '';
//     }
//
//     get unaToReplyFilter(): string {
//         return this._unaToReplyFilter ? this._unaToReplyFilter : 'Organisation';
//     }
//
//     set unaToReplyFilter(filter: string) {
//         this._unaToReplyFilter = filter;
//     }
//
//     get unaRepliedFilter(): MapRequestSearchField {
//         return this._unaRepliedFilter;
//     }
//
//     set unaRepliedFilter(filter: MapRequestSearchField) {
//         this._unaRepliedFilter = filter;
//     }
//
//     get mriFilter(): MapRequestSearchField {
//         return this._mriFilter;
//     }
//
//     set mriFilter(filter: MapRequestSearchField) {
//         this._mriFilter = filter;
//     }
//
//     get currentMriPage() {
//         return this._currentMriPage;
//     }
//
//     set currentMriPage(currentPage: number) {
//         this._currentMriPage = currentPage;
//     }
//
//     get unaToReplyPage() {
//         return this._unaToReplyPage;
//     }
//
//     set unaToReplyPage(currentPage: number) {
//         this._unaToReplyPage = currentPage;
//     }
//
//     get unaRepliedPage() {
//         return this._unaRepliedPage;
//     }
//
//     set unaRepliedPage(currentPage: number) {
//         this._unaRepliedPage = currentPage;
//     }
//
//     get unaToConfirmPage() {
//         return this._unaToConfirmPage;
//     }
//
//     set unaToConfirmPage(currentPage: number) {
//         this._unaToConfirmPage = currentPage;
//     }
//
//     public unaRepliedFilterHasChanged(data: KlipApi.IGetMapRequestsRepliedInput): boolean {
//         if (!this._unaRepliedFilter) {
//             return false;
//         }
//
//         let fromDateChanged = false;
//         if (this._unaRepliedFilter.date.from && this._unaRepliedFilter.date.from[0]) {
//             fromDateChanged = data.startDate !== this._unaRepliedFilter.date.from[0];
//         }
//
//
//         let toDateChanged = false;
//         if (this._unaRepliedFilter.date.to && this._unaRepliedFilter.date.to[0]) {
//             toDateChanged = data.endDate !== this._unaRepliedFilter.date.to[0];
//         }
//
//
//         const searchField = KlipApi.SearchField[this._unaRepliedFilter.searchField as keyof typeof KlipApi.SearchField];
//         const searchOption = KlipApi.SearchOption[this._unaRepliedFilter.searchOption as keyof typeof KlipApi.SearchOption];
//
//         return fromDateChanged || toDateChanged || data.query !== this._unaRepliedFilter.keyword ||
//             data.searchField !== searchField ||
//             data.searchOption !== searchOption ||
//             data.isArchived !== this._unaRepliedFilter.archive;
//     }
//
//     public mriFilterHasChanged(data: KlipApi.IGetMapRequestsInput): boolean {
//         if (!this._mriFilter) {
//             return false;
//         }
//
//         let fromDateChanged = false;
//         if (this._mriFilter.date.from && this._mriFilter.date.from[0]) {
//             fromDateChanged = data.startDate !== this._mriFilter.date.from[0];
//         }
//
//
//         let toDateChanged = false;
//         if (this._mriFilter.date.to && this._mriFilter.date.to[0]) {
//             toDateChanged = data.endDate !== this._mriFilter.date.to[0];
//         }
//
//         const searchField = KlipApi.SearchField[this._mriFilter.searchField as keyof typeof KlipApi.SearchField];
//         const searchOption = KlipApi.SearchOption[this._mriFilter.searchOption as keyof typeof KlipApi.SearchOption];
//
//         return fromDateChanged || toDateChanged || data.query !== this._mriFilter.keyword ||
//             data.searchField !== searchField ||
//             data.searchOption !== searchOption ||
//             data.isArchived !== this._mriFilter.archive;
//     }
// }

export interface IFilters {
    toCurrency: (value: number) => string;
    periodStringFromDates: (startDate: string, endDate: string) => string;
}

export default {
    install(vue: typeof Vue): void {

        const toCurrency = (value) => {
            if (typeof value !== "number") {
                return value;
            }
            const formatter = new Intl.NumberFormat('nl-BE', {
                style: 'currency',
                currency: 'EUR'
            });
            return formatter.format(value);
        };

        // vue.filter('toCurrency', toCurrency);

        // const unaFilter = new UnaFilter();

        Object.defineProperties(vue.prototype, {
            // $filter: {
            //     get() {
            //         return unaFilter;
            //     },
            // },
            $filters: {
                get() {
                    return {
                        toCurrency,
                        periodStringFromDates: DateUtil.periodStringFromDates
                    }
                }
            }
        });
    },
};
