import {escape} from 'html-escaper';

export default class OrganisationHelper {
    public static setDepth(depth: number, name: string): string {
        let left = "";
        for (let index = 0; index < depth; index++) {
            left += "&nbsp; ";
        }
        return left += escape(name);
    }
}