import { KlRouteConfig } from '@/router/router-vue';
import KlPublic from './public.vue';
import KlRegistration from './registration.vue';
import { klipIndex } from '@/router/router';

export const publicRoute: KlRouteConfig = {
    path: '/public',
    name: 'public',
    component: KlPublic,
    meta: {
        breadcrumb: { title: '', parent: klipIndex },
        allowAnonymous: true,
    },
};

export const publicRegistrationRoute: KlRouteConfig = {
    path: '/registration',
    name: 'registration',
    component: KlRegistration,
    meta: {
        breadcrumb: { title: '', parent: klipIndex },
        allowAnonymous: true,
    },
};


export default [publicRoute, publicRegistrationRoute];

