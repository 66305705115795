import { Vue } from 'vue-property-decorator';

interface DescriptionListRow {
  label: string;
  modCollapse: boolean;
}

// TODO: vue3 migration
// This is a functional component, because it renders 2 root nodes (<dt> <dd>).
// In vue3 a normal component can also render 2 root nodes = refactor in vue3
// ref: https://v3-migration.vuejs.org/breaking-changes/functional-components
export default Vue.component<DescriptionListRow>('DescriptionListRow', {
  functional: true,
  render: (createElement, context) => {
    if (context.props.label || context.children) {
      return [
        createElement(
          'dt', {
          class: `kl-description-list__term${context.props.modCollapse ?
            ` kl-description-list__term--collapse` : ''}`,
        },
          context.props.label,
        ),
        createElement(
          'dd', {
          class: `kl-description-list__description${context.props.modCollapse ?
            ` kl-description-list__description--collapse` : ''}`,
        },
          context.children,
        ),
      ];
    }
    return null;
  },
});
