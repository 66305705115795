import {computed, defineComponent, ref} from "vue";
import {useFeatureToggleStore} from '@/stores/feature-toggle-store';

export default defineComponent({
    setup() {
        const searchFeatureFlags = ref();

        const cellParser = (row: IRow, column: IColumn) => {
            switch (column.key) {
                default:
                    const cellValue = row[column.key] != null ? row[column.key] : '&nbsp;';
                    let valueClass = '';
                    if((cellValue + '').toLowerCase() === 'true'){
                        valueClass += ' vl-value-true'
                    }
                    else if((cellValue + '').toLowerCase() === 'false'){
                        valueClass += ' vl-value-false'
                    }
                    else if((cellValue + '').match(/[1-9][0-9][0-9]{2}-([0][1-9]|[1][0-2])-([1-2][0-9]|[0][1-9]|[3][0-1])/gm)){
                        const date = new Date(cellValue);
                        if(date > new Date()){
                            valueClass += ' vl-value-false'
                        }else {
                            valueClass += ' vl-value-true'
                        }
                    }
                    return {
                        template: `<span class="ippro-datatable__cell ${valueClass}">${cellValue}</span>`,
                    };
            }
        };

        const featureFlagsColumns = ref<IColumn[]>([
            {
                key: 'name',
                label: 'Naam',
                sortable: true,
                isVisible: true
            },
            {
                key: 'description',
                label: 'Omschrijving',
                sortable: true,
                isVisible: true
            },
            {
                key: 'valueCurrentEnvironment',
                label: 'Current',
                sortable: true,
                isVisible: true,
                parser: cellParser,
                width: 100,
                widthUnit: 'px',
            },
            {
                key: 'valueTest',
                label: 'Test',
                sortable: true,
                isVisible: true,
                parser: cellParser,
                width: 100,
                widthUnit: 'px',
            },
            {
                key: 'valueBeta',
                label: 'Beta',
                sortable: true,
                isVisible: true,
                parser: cellParser,
                width: 100,
                widthUnit: 'px',
            },
            {
                key: 'valueProd',
                label: 'Prod',
                sortable: true,
                isVisible: true,
                parser: cellParser,
                width: 100,
                widthUnit: 'px',
            }
        ]);

        const featureFlagsMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 0, resultsPerPage: 50, currentPage: 1 });

        const featureFlags = computed(() => useFeatureToggleStore().featureFlags);
        const featureFlagsFetching = computed(() => !useFeatureToggleStore().fetched);

        return {
            featureFlagsColumns,
            featureFlagsMeta,
            featureFlags,
            featureFlagsFetching,
            searchFeatureFlags,
        }
    }
})
