import {computed, defineComponent, ref} from 'vue';

export default defineComponent({
    name: 'KlAccordion',
    emits: ['toggle'],
    props: {
        title: {
            type: String,
            required: true,
        },
        tagName: {
            type: String,
            required: false,
            default: 'div'
        },
        subtitle: {
            type: String,
            required: false,
            default: ''
        },
        modError: {
            type: Boolean,
            required: false,
            default: false
        },
        modSuccess: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    setup(props, {emit}) {

        const open = ref<boolean>(false);

        const classes = computed(() => {
            return [
                'kl-accordion', {
                    'kl-accordion--error': props.modError,
                    'kl-accordion--success': props.modSuccess,
                }];
        });

        const ariaHidden = computed(() => {
            return open.value ? 'false' : 'true';
        });

        const ariaExpanded = computed(() => {
            return open.value ? 'true' : 'false';
        });

        const toggleIcon = computed(() => {
            return open.value ? 'arrow-up-fat' : 'arrow-down-fat';
        });


        const toggle = () => {
            open.value = !open.value;
            emit('toggle', open.value);
        }

        const beforeEnter = (el: { style: { height: string; }; }) => {
            el.style.height = '0';
        }

        const enter = (el: { style: { height: string; }; scrollHeight: string; }) => {
            el.style.height = el.scrollHeight + 'px';
        }

        const beforeLeave = (el: { style: { height: string; }; scrollHeight: string; }) => {
            el.style.height = el.scrollHeight + 'px';
        }

        const leave = (el: { style: { height: string; }; }) => {
            el.style.height = '0';
        }

        return {
            open, classes, toggleIcon, ariaHidden, ariaExpanded,
            toggle, beforeEnter, enter, beforeLeave, leave,
        }
    }
})
