
import { KlRouteConfig, KlRoute } from '@/router/router-vue';
import KlSettings from './settings.vue';
import { klipIndex } from '@/router/router';

export const settings: KlRouteConfig = {
    path: '/settings',
    name: 'settings',
    component: KlSettings,
    meta: {
        breadcrumb: { title: 'Instellingen', parent: klipIndex },
    },
};

export const organisationSettings: KlRouteConfig = {
    path: '/settings',
    name: 'settings',
    hash: '#organisation',
    component: KlSettings,
    meta: {
        breadcrumb: { title: 'Instellingen', parent: klipIndex },
    },
};

export const Profile: KlRouteConfig = {
    path: '/profile',
    redirect: settings,
};

export default [settings, Profile];

