﻿import { computed, defineComponent, ref } from 'vue';
import { RegistrationModel, RegistrationType } from "@/app/public/registrationModel";

export default defineComponent({
    props: {
        registrationModel: {
            type: RegistrationModel,
            required: true
        }
    },
    emits: ['change'],
    setup(props, { emit }) {
        const kboLink = 'https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?lang=nl';
        const ovoLink = 'https://wegwijs.vlaanderen.be/#/organisations';
        const organisationCodeAnnotation = ref<string>(`<a href="${kboLink}" target="_blank" class="vl-link">KBO-nummer</a> of <a href="${ovoLink}" target="_blank" class="vl-link">OVO-code</a> van uw organisatie opzoeken`);

        const isCitizen = computed((): boolean => {
            return props.registrationModel.registrationType === RegistrationType.Citizen;
        });

        const organisationCode = computed({
            get: () => props.registrationModel.organisationCode,
            set: (value) => {
                props.registrationModel.setOrganisationCode(value);
                emit('change', props.registrationModel);
            }
        });

        const isLegalRepresentative = computed({
            get: () => props.registrationModel.isLegalRepresentative,
            set: (value) => {
                props.registrationModel.setLegalRepresentative(value);
                emit('change', props.registrationModel);
            }
        });

        return {
            isCitizen,
            isLegalRepresentative,
            organisationCode,
            organisationCodeAnnotation
        }
    }
});