import {defineComponent} from 'vue';

export interface IUnaMessage {
  title: string;
  content?: string;
}

export default defineComponent({
    name: 'KlUnaMessage',
    props: {
        title: {
            type: String, default: '',
        },
        titleTag: {
            type: String, required: false, default: 'span',
        },
        tagName: {
            type: String, default: 'div',
        },
        content: {
            type: String, default: '',
        },
    },
    setup(props) {
    }
})
