import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import * as KlipApi from '@/api/klip-api.proxy';
import { IUnaMessage } from '../../kl-maprequest-una-table/components/kl-una-message/kl-una-message';

export default defineComponent({
    name: 'KlPdaUnaZones',
    props: {
        zoneId: {
            type: String,
            default: null,
            required: true
        },
        organisationId: {
            type: String,
            default: null,
            required: false
        },
        parentOrganisationId: {
            type: String,
            default: null,
            required: true
        }
    },
    setup(props) {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const unaZones = ref<KlipApi.UnaZoneListItem[]>(null);

        const unaMessage = computed((): IUnaMessage => {
            return {
                title: `LET OP! We tonen hier enkel KLIP-leidingbeheerders.`,
                content: 'Leidingbeheerders van KLIM kunnen niet getoond worden.',
            };
        });

        const fetchUnaZones = async (zoneId: string) => {
            const response = await proxy.pda_GetUnaZonesByZoneId(zoneId, props.organisationId, props.parentOrganisationId);

            if (!response.result) {
                return;
            }

            unaZones.value = response.result;
        }

        const accordionTitle = (unaZone: KlipApi.UnaZoneListItem): string => {
            return `<p><strong>${unaZone.name}</strong> - ${unaZone.zoneName}</p>`;
        }

        onMounted(async () => {
            fetchUnaZones(props.zoneId);
        });

        return {
            unaMessage,
            unaZones,
            accordionTitle
        };
    }
});
