import { event } from 'vue-gtag';

export enum EAnalyticsCategory {
    drawZone = 'draw-zone',
}

export enum EAnalyticsDrawZoneActions {
    import = 'draw-zone: import',
    draw = 'draw-zone: draw',
}

export class AnalyticsService {
    public static SendEvent(category: EAnalyticsCategory, action: string, label?: string) {
        try {
            event(action, {
                event_category: category,
                event_label: label,
            });
        }
        catch (e) {}
    }
}
