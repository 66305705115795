import { computed, defineComponent, ref, watch } from "vue";
import { escape } from 'html-escaper';
import * as KlipApi from "@/api/klip-api.proxy";
import { IpproDatatable } from "@/app/shared/components/ippro-datatable/ippro-datatable-declare";
import { useRouter } from "@/plugins/routes";
import { AdminSubOrganisationDetail } from "@/app/admin/admin-routes";

export default defineComponent({
    props: {
        organisation: {
            type: KlipApi.OrganisationDetail,
            default: null,
            required: true
        }
    },
    setup(props) {
        const router = useRouter();
        const organisationDetail = ref<KlipApi.OrganisationDetail>(null);
        const subOrganisationsMeta = ref<{ totalRows: number, resultsPerPage: number, currentPage: number | undefined }>({ totalRows: 0, resultsPerPage: 50, currentPage: 1 });
        const onlySearchActiveOrganisations = ref<boolean>(true);

        const search = (value: boolean) => {
            onlySearchActiveOrganisations.value = value;
        };

        const subOrganisations = computed(() => {
            return organisationDetail.value.organisationsDataTable.filter(o => o.lockedOut === false || !onlySearchActiveOrganisations.value);
        });

        const organisationChanged = (organisation: KlipApi.OrganisationDetail) => {
            organisationDetail.value = organisation;
        };

        const routeAdminSubOrganisation = computed(() => {
            return AdminSubOrganisationDetail;
        })

        const cellParser = (row: IRow, column: IColumn) => {
            const routeParams = {
                organisationId: row.id
            };

            const cellValue = escape(row[column.key]);
            if (column.key === 'name') {
                if (!row.lockedOut) {
                    return {
                        template: `<kl-router-link :to='${JSON.stringify(routeAdminSubOrganisation.value)}' style='margin-left:${1 + row.depth - 1}em' :params='${JSON.stringify(routeParams)}'>${cellValue}</kl-router-link>`
                    };
                } else {
                    return {
                        template: `<span style='margin-left:${1 + row.depth - 1}em'>${cellValue} (verwijderd)</span>`
                    };
                }                
            } 
            if (column.key === 'numberOfUtilityNetworkAuthorityZones') {
                if (!row.lockedOut) {
                    return {
                        template: `
                            <kl-router-link :to='${JSON.stringify(routeAdminSubOrganisation.value)}' class="align-right" :params='${JSON.stringify(routeParams)}'>
                                ${row.hasKlimZone ? `<img src="/img/logo-klim.svg" class="klim-pictogram" alt="KLIM pictogram" />` : ''}
                                <span>${cellValue}</span>
                            </kl-router-link>`,
                    };
                } else {
                    return {
                        template: `
                            <div class="align-right">
                                ${row.hasKlimZone ? `<img src="/img/logo-klim.svg" class="klim-pictogram" alt="KLIM pictogram" />` : ''}
                                <span>${cellValue}</span>
                            </div>`,
                    };
                }                
            }

            if (!row.lockedOut) {
                return {
                    template: `<kl-router-link :to='${JSON.stringify(routeAdminSubOrganisation.value)}' :params='${JSON.stringify(routeParams)}'>${cellValue}</kl-router-link>`
                };
            } else {
                return {
                    template: `<span>${cellValue}</span>`
                };
            }
            
        }

        const subOrganisationsColumns = ref<IColumn[]>([
            {
                key: 'name',
                label: "Organisatie",
                sortable: false,
                isVisible: true,
                width: 7,
                parser: cellParser
            },
            {
                key: 'utilityNetworkAuthorityName',
                label: 'Naam als leidingbeheerder in KLIP',
                sortable: false,
                isVisible: true,
                width: 7,
                parser: cellParser
            },
            {
                key: "numberOfUtilityNetworkAuthorityZones",
                type: "String",
                label: "Aantal zones",
                sortable: false,
                width: 2,
                isVisible: true,
                parser: cellParser
            }
        ]);


        const rowClicked = async (row: IpproDatatable.IRow) => {
            await router.push({ name: AdminSubOrganisationDetail.name, params: { organisationId: row.id } });
        }


        watch(props.organisation, organisationChanged, { immediate: true, deep: true });

        return {
            organisationDetail,
            subOrganisationsColumns,
            subOrganisationsMeta,
            subOrganisations,
            rowClicked,
            search
        };
    }
});
