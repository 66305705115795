export default class Guid {
    public static randomGuid() {
        let g = '';
        for (let i = 0; i < 8; i++) {
          g += i > 1 && i < 6 ? '-' : '';
          g += Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        return g;
      }
}
