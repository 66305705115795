import _Vue from 'vue';
// import { IpproIconText } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-icon-text';
// import { IpproDatatable } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-datatable';
// import { IpproMap } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map';
import { IpproIconText } from '@/app/shared/components/ippro-icon-text';
import { IpproDatatable } from '@/app/shared/components/ippro-datatable';
import { IpproMap } from '@/app/shared/components/ippro-map';
import { IpproAutocomplete } from '@/app/shared/components/ippro-autocomplete';

export default {
  install(Vue: typeof _Vue, options?: any): void {
    Vue.component('ippro-icon-text', IpproIconText);
    Vue.component('ippro-datatable', IpproDatatable);
    Vue.component('ippro-map', IpproMap);
    Vue.component('ippro-autocomplete', IpproAutocomplete);
  },
};

export { IpproIconText };
export { IpproDatatable };
export { IpproMap };
export { IpproAutocomplete };
