import {computed, defineComponent} from 'vue';

export interface IUnaAlert {
    message: string;
    icon?: string;
    status: IUnaAlertStates;
}

export enum IUnaAlertStates {
    error = 'error',
    warning = 'warning',
    success = 'success',
}

export default defineComponent({
    name: 'KlUnaAlert',
    props: {
        tagName: {
            type: String, required: false, default: 'div',
        },
        modIcon: {
            type: String, required: false, default: '',
        },
        modColored: {
            type: Boolean, required: false, default: false,
        },
        modSuccess: {
            type: Boolean, required: false, default: false,
        },
        modError: {
            type: Boolean, required: false, default: false,
        },
        modWarning: {
            type: Boolean, required: false, default: false,
        },
        helpLink: {
            type: String, required: false, default: '',
        },
    },
    setup(props) {

        const classes = computed(() => {
            return [
                'kl-una-alert', {
                    'kl-una-alert--colored': props.modColored,
                    'kl-una-alert--error': props.modError,
                    'kl-una-alert--success': props.modSuccess,
                    'kl-una-alert--warning': props.modWarning,
                }];
        });

        const icon = computed(() => {
            return props.modIcon ? props.modIcon : props.modError ?
                'alert-circle-filled' : props.modSuccess ?
                    'check-filled' : props.modWarning ?
                        'alert-triangle-filled' : null;
        });

        return { classes, icon }
    }
})
