import Vue from 'vue';
import { KlipApiProxy } from '@/api/klip-api.proxy';
import { httpClient } from '@/app/shared/service/http-client';

const baseUrl = `${import.meta.env.VITE_BASE_URI}`;

export const klipApiProxy = new KlipApiProxy(baseUrl, httpClient);

export default {
    install(vue: typeof Vue): void {
        vue.prototype.$client = klipApiProxy;
    },
};

export function useKlipApiProxy(): KlipApiProxy {
    return klipApiProxy;
}
