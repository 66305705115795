import {Vue} from "vue-property-decorator";
import ClickOutside from "./click-outside";
import {computed, defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';

export interface AutoCompleteSelection {
    name: string;
    obj?: any;
    type?: any;
}

export interface AutoCompleteResult {
    title: string;
    subtitle?: string;
    value: AutoCompleteSelection;
    selected?: boolean;
}

Vue.directive("click-outside", ClickOutside);


export default defineComponent({
    name: 'IpproAutocomplete',
    emits: ['select'],
    props: {
        data: {
            type: Array as () => AutoCompleteResult[],
            required: true,
        },
        fetching: {
            type: Boolean,
            default: false,
        },
        feedback: {
            type: String,
            default: '',
        },
        helpText: {
            type: String,
            default: 'Gebruik de pijltjes (boven en onder) om, na het invoeren van X karakters, door de suggesties van de suggestiebox te navigeren. Bevestig je keuze met &quot;enter&quot; of gebruik de &quot;escape&quot; knop om te suggestiebox te sluiten.',
        },
        modRequired: {
            type: Boolean,
            default: false,
        },
        modSelectFirstResult: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, {emit}) {

        const inputField = ref(null); // = vl-input-field

        const searchText = ref<string>('');
        const results = ref<AutoCompleteResult[]>([]);
        const resultsVisible = ref(true);

        const hasResults = computed(() => {
            return results.value.length;
        });

        const _reset = () => {
            results.value = [];
        }

        const hideResults = () => {
            resultsVisible.value = false;
        }

        const showResults = () => {
            resultsVisible.value = true;
        }

        const navigateResults = (direction: string) => {
            if (results.value.length) {
                const currentResult = results.value.findIndex(result => result.selected);
                const newResult =
                    direction === "down"
                        ? currentResult === results.value.length - 1
                            ? 0
                            : currentResult + 1
                        : !currentResult || currentResult === 0
                            ? results.value.length - 1
                            : currentResult - 1;
                results.value = results.value.map(obj => ({...obj, selected: false}));
                results.value[newResult].selected = true;
            }
        }

        const arrowDown = () => {
            if (results.value && !resultsVisible.value) {
                resultsVisible.value = true;
            } else {
                navigateResults("down");
            }
        }

        const arrowUp = () => {
            navigateResults("up");
        }

        const selectFocussedResult = () => {
            const focussedResult = results.value.filter(obj => obj.selected);
            if (results.value && resultsVisible.value && focussedResult[0]) {
                select(focussedResult[0].value);
                if (event) {
                    event.preventDefault();
                }
            }
        }

        const select = (item: any) => {
            searchText.value = item.name;
            emit("select", item);

            _reset();
        }

        const focus = () => {
            inputField.value.$el.focus();
            inputField.value.$el.select();
        }

        watch(
            () => props.data,
            (newData) => {
                results.value = (newData || []).map((obj, index) => ({
                    ...obj,
                    selected: props.modSelectFirstResult && index === 0
                }));
            },
            {immediate: true, deep: true}
        )

        watch(
            results,
            () => {
                if (hasResults.value) {
                    showResults();
                }
            },
            {immediate: true, deep: true}
        )

        onMounted(() => {
            inputField.value = getCurrentInstance()?.proxy?.$refs['inputField'];
        })

        return {
            searchText,
            results,
            resultsVisible,
            hasResults,
            hideResults,
            showResults,
            arrowDown,
            arrowUp,
            selectFocussedResult,
            select,
            navigateResults,

            focus,
        }
    }
})
