import { IMapRequestResponderData } from '@/api/klip-api.proxy';
import {computed, defineComponent} from 'vue';

export interface MapRequestExtendedResponderData extends IMapRequestResponderData {
    klim?: boolean | undefined;
}

export interface IRespondersAccordion {
    title: string;
    subtitle?: string;
    error: boolean;
    success: boolean;
    statusMessage?: string;
    contact: {
        title?: string;
        phone?: string;
        email?: string;
        emergencyPhone?: string;
    };
}

export default defineComponent({
    name: 'KlMaprequestUnaTable',
    props: {},
    setup(props) {

        const classes = computed(() => {
            return [
                'kl-una-table',
            ];
        });

        const childClasses = computed(() => {
            return [
                'kl-una-table__item',
            ];
        });

        return {classes, childClasses}
    }
})
