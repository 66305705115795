import {computed, defineComponent, ref} from 'vue';
import Guid from '@/app/shared/helpers/guid';

export default defineComponent({
    name: 'KlInputCopyClipboard',
    props: {
        value: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        }
    },
    setup(props) {

        const id: string = 'copy-to-clipboard-input-' + Guid.randomGuid();

        const copyToClipBoard = (controlId: string) => {
            const control: any = document.getElementById(controlId);
            control.select();
            document.execCommand('copy');
        }

        return {
            id, copyToClipBoard
        }
    }
})
