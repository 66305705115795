import { MapRequestPrintView } from '@/api/klip-api.proxy';
import dayjs from 'dayjs';

export class ViewerPrintModel {
    public id: string;
    public dateRequested: string;
    public downloadUrl: string;
    public fileSize: number;
    public isBusy: boolean;
    public options: string;

    constructor(mapRequestPrintView: MapRequestPrintView) {
        this.id = mapRequestPrintView.printId;
        this.dateRequested = dayjs(mapRequestPrintView.timestamp).format('DD/MM/YYYY - HHumm');
        this.fileSize = mapRequestPrintView.fileSize;
        this.isBusy = !mapRequestPrintView.isProcessed;
        this.downloadUrl = mapRequestPrintView.downloadMapRequestPrintPackageUrl;
        this.options = this.printSettingsDisplay(mapRequestPrintView);
    }
    private printSettingsDisplay(mapRequestPrintView: MapRequestPrintView): string {
        let result = '';

        const addToResult = (text: string, separator: string) => {
            if (text) {
                if (result) {
                    result += separator;
                }
                result += text;
            }
        };

        const getFromMap = (key: number | string, map: any) => {
            return map[key];
        };


        addToResult(getFromMap(mapRequestPrintView.zoomLevel, {
            0: 'Planaanvraagzone',
            1: 'Zichtbaar gedeelte',
        }), '<br>');
        addToResult(getFromMap(mapRequestPrintView.content, {
            1: 'per thema',
            2: 'per KLB',
            3: 'per KLB en per thema',
        }), '<br>');
        addToResult(getFromMap(mapRequestPrintView.resolution, {
            2500: '1/2500',
            1000: '1/1000',
            500: '1/500',
            250: '1/250',
        }), ' - ');
        addToResult(getFromMap(mapRequestPrintView.paperSize, {
            onePage: '1 pagina',
            A0: 'A0',
            A3: 'A3',
            A4: 'A4',
        }), '<br>');

        if (mapRequestPrintView.extraPlans) {
            result += ' + Extra plannen';
        }

        return result;
    }
}
