import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'IpproIconText',
  props: {
    tagName: {
      type: String,
      default: 'span',
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    modIconBefore: {
      type: Boolean,
      default: false,
      required: false,
    },
    modIconAfter: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {

    const iconClasses = computed(() => [
      "ippro-icon-text__icon",
      {
        "ippro-icon-text__icon--before": props.modIconBefore,
        "ippro-icon-text__icon--after": props.modIconAfter
      }
    ]);

    return { iconClasses };
  }
})
