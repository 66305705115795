<template>
    <vl-button mod-naked-action @click.prevent="onEditKlbZone()"
               @mouseover="onKlbZoneHover()"
               class="kl-sidebar__section__zone-summary kl-sidebar__section__zone-summary__for-klb-zones">
        <vl-link href="#" class="kl-sidebar__section__zone-summary__title">
            <img src="/img/logo-klim.svg" class="klim-pictogram" alt="KLIM pictogram"
                 v-if="zone.orgZoneInfo?.isKlim"/>
            <strong>{{ zone.name }}</strong>
        </vl-link>
        <div class="kl-sidebar__section__zone-summary__sub" v-if="getKlbZoneListInfo(zone)"
             v-html="getKlbZoneListInfo(zone)" v-vl-text.small></div>
    </vl-button>
</template>

<script lang="ts">

import {defineComponent} from 'vue';
import {IDrawZone} from '@/app/shared/components/kl-draw-zone-map/components/kl-draw-zone-sidebar/kl-draw-zone-sidebar';
import DateUtil from '@/app/shared/helpers/date-util';

export default defineComponent({
    name: 'KlDrawZoneSidebarKlbListItem',
    emits: ['edit-klb-zone', 'highlight-klb-zone'],
    props: {
        zone: {
            type: Object as () => IDrawZone,
            default: null,
        }
    },
    setup(props, { emit }) {

        const getKlbZoneListInfo = (cz: IDrawZone): string => {
            let orgZoneInfo = `${cz.orgZoneInfo?.guid}<br/>${cz.orgZoneInfo?.namespace}`;
            if (!cz.orgZoneInfo?.isArchived && cz.orgZoneInfo?.willBeActivatedOn && !cz.orgZoneInfo.isKlim) {
                orgZoneInfo += `<br/>Wordt geactiveerd op ${DateUtil.formatDate(cz.orgZoneInfo?.willBeActivatedOn)}`;
            }

            return orgZoneInfo;
        }

        const onEditKlbZone = () => {
            emit('edit-klb-zone');
        }

        const onKlbZoneHover = () => {
            emit('highlight-klb-zone');
        }

        return {
            getKlbZoneListInfo,
            onEditKlbZone,
            onKlbZoneHover,
        }
    }
})

</script>

<style scoped lang="scss">
@import "./kl-sidebar.scss";
</style>
