import { Component, Vue } from 'vue-property-decorator';
import {
    Organisation,
    OrganisationInvoiceData,
    OrganisationSettingsInput,
    PaymentTypes,
    SubOrganisation
} from '@/api/klip-api.proxy';
import { organisationSettings } from '@/app/settings/settings-routes';
import {computed, defineComponent, onMounted, ref} from 'vue';
import {useKlipApiProxy} from '@/plugins/proxy-client';
import {useRouter} from '@/plugins/routes';

export default defineComponent({
    name: 'KlCreateSubOrganisation',
    props: {},
    setup(props) {

        const router = useRouter()

        const organisationParentId = ref<string>('');
        const organisationName = ref<string>('');
        const isSending = ref<boolean>(false);
        const isLoading = ref<boolean>(true);

        const suborganisations = ref<SubOrganisation[]>([]);
        const invoiceData = ref<OrganisationInvoiceData>();

        const routeSettings = computed(() => {
            return organisationSettings;
        });

        const setDepth = (depth: string, name: string) => {
            const currentDepth = +depth;
            let left = "";
            for (let index = 0; index < currentDepth; index++) {
                left += "&nbsp; ";
            }
            return left + name;
        }

        const submit = () => {
            isSending.value = true;
            const organisationInput = new OrganisationSettingsInput();
            organisationInput.organisation = new Organisation();
            organisationInput.organisation.name = organisationName.value;
            organisationInput.organisation.parentId = organisationParentId.value;

            if (invoiceData.value) {
                organisationInput.organisationInvoiceData = new OrganisationInvoiceData();
                organisationInput.organisationInvoiceData.attention = invoiceData.value.attention;
                organisationInput.organisationInvoiceData.email = invoiceData.value.email;
                organisationInput.organisationInvoiceData.reference = invoiceData.value.reference;
                organisationInput.organisationInvoiceData.vatNumber = invoiceData.value.vatNumber;
            }

            useKlipApiProxy().organisation_CreateOrganisation(organisationInput).then(async () => {
                await router.push({ name: routeSettings.value.name, hash: "#organisation" });
            }).finally(() => {
                isSending.value = false;
            });
        }

        onMounted(() => {
            useKlipApiProxy().organisation_LoadCreateOrganisationView().then((view) => {
                suborganisations.value = view.result.subOrganisations;
                invoiceData.value = view.result.invoiceData;
            }).finally(() => {
                isLoading.value = false;
            });
        });

        return {
            organisationParentId,
            organisationName,
            isSending,
            isLoading,
            suborganisations,
            invoiceData,

            setDepth,
            submit,
        }
    }
})
