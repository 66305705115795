﻿import { computed, defineComponent } from 'vue';
import { RegistrationModel } from "@/app/public/registrationModel";

export default defineComponent({
    props: {
        registrationModel: {
            type: RegistrationModel,
            required: true
        }
    },
    emits: ['change'],
    setup(props, { emit }) {
        const roles = computed({
            get: () => props.registrationModel.roles,
            set: (value) => {
                props.registrationModel.setRoles(value);
                emit('change', props.registrationModel);
            }
        });

        return {
            roles
        }
    }
});