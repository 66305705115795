import { Vue } from "vue-property-decorator";
import Map from "ol/Map";
import { default as View, ViewOptions } from "ol/View";
import Projection from "ol/proj/Projection";
import { addProjection } from "ol/proj";
import MousePosition from "ol/control/MousePosition";
import { createStringXY } from "ol/coordinate";
import ScaleLine from "ol/control/ScaleLine";
import IpproMapLayerToggle from "./components/ippro-map-layer-toggle/ippro-map-layer-toggle.vue";
import IpproMapFullscreen from "./components/ippro-map-fullscreen/ippro-map-fullscreen.vue";
import {
  IpproMapLayer,
  projectionExtent,
  resolutions,
  registeredLayers,
  defaultStyle,
  IpproMapLayerSetting
} from "./vl-ippro-map-layers";
import { Extent, boundingExtent, getCenter } from "ol/extent";
import Feature, { FeatureLike } from "ol/Feature";
import { VlTooltip } from "@govflanders/vl-ui-vue-components";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import VectorLayer from "ol/layer/Vector";
import Geometry from "ol/geom/Geometry";
import GeometryCollection from "ol/geom/GeometryCollection";
import WKT from "ol/format/WKT";
import Select from "ol/interaction/Select";
import { click } from "ol/events/condition";
import { Tile } from "ol/layer";
import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import { ProjectionLike } from 'ol/proj';
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import { getCurrentInstance } from "vue";

interface IpproViewOptions extends ViewOptions {
  constrainResolution?: boolean;
}

// export interface IpproMapType extends IpproMap {
//   olMap: Map;
// }
export interface IpproMapType {
  olMap: Map;
}

export interface IpproMapFeatureStyle {
  default: Style;
  hover?: Style;
  active?: Style;
}
export interface IpproMapZone {
  meta?: {
    id?: string;
    ref?: string;
    title?: any;
    description?: string;
  };
  visible?: boolean;
  geometry: String;
  style?: Style;
  styleType?: EntityStyleSettings;
  mapStyle?: MapStyles;
  linkedZone?: IpproMapZone;
}
export interface EntityStyleSettings {
  primary: string;
  mapStyle: {
    default: EntityLayerStateStyleParams;
    hover: EntityLayerStateStyleParams;
    active: EntityLayerStateStyleParams;
  };
}
export interface EntityLayerStateLevelStyleParams {
  backgroundColor: string;
  borderColor: string;
  borderThickness: number;
}
export interface EntityLayerStateStyleParams {
  primary: EntityLayerStateLevelStyleParams;
  secondary: EntityLayerStateLevelStyleParams;
}
export enum MapStyles {
  Primary = "primary",
  Secondary = "secondary"
}

proj4.defs(
  "EPSG:31370",
  "+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.868628,52.297783,-103.723893,0.336570,-0.456955,1.842183,-1.2747 +units=m +no_defs"
);
proj4.defs(
  "EPSG:3857",
  "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs"
);

proj4.defs("EPSG:3812", "+proj=lcc +lat_0=50.797815 +lon_0=4.35921583333333 +lat_1=49.8333333333333 +lat_2=51.1666666666667 +x_0=649328 +y_0=665262 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");

proj4.defs("EPSG:4326", "+proj=longlat +datum=WGS84 +no_defs +type=crs");

register(proj4);

Vue.directive("vl-tooltip", VlTooltip);

export default defineComponent({
  name: 'IpproMap',
  components: {
    IpproMapLayerToggle,
    IpproMapFullscreen,
  },
  emits: ['feature-added', 'read-wkt-failed', 'toggle-full-screen', 'features-selected', 'click', 'features-clicked', 'view-updated', 'mouseover', 'mouseout'],
  props: {
    id: {
      type: String,
      default: `ippro-map-${Math.random().toString(36).substring(7)}`
    },
    value: {
      type: Array as () => IpproMapZone[],
      default: (): IpproMapZone[] => [],
    },
    modFitToWkt: {
      type: Boolean,
      default: true,
    },
    zoom: {
      type: Number,
      default: 2,
    },
    maxZoom: {
      type: Number,
      default: 15,
    },
    minZoom: {
      type: Number,
      default: 1,
    },
    constrainResolution: {
      type: Boolean,
      default: true,
    },
    enableRotation: {
      type: Boolean,
      default: true,
    },
    center: {
      type: Array as () => number[],
      default: (): number[] => [51.03675, 3.70811],
    },
    resolutions: {
      type: Array,
      default: () => resolutions,
    },
    defaultLayerId: {
      type: String,
      default: "grb_bsk",
    },
    backgroundColor: {
      type: String,
      default: "#e8ebee",
    },
    modZoom: {
      type: Boolean,
      default: true,
    },
    modToggleFullscreen: {
      type: Boolean,
      default: false,
    },
    modFullscreen: {
      type: Boolean,
      default: false,
    },
    modDisableScrollZoom: {
      type: Boolean,
      default: false,
    },
    modAbsoluteZoom: {
      type: Boolean,
      default: false,
    },
    modZoomToFlandersOnInit: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: "De kaart is aan het laden",
    },
    modLayers: {
      type: Array as () => string[],
      default: (): string[] => [],
    },
    layerSettings: {
      type: Array as () => IpproMapLayerSetting[],
      default: (): IpproMapLayerSetting[] => [],
    },
    projection: {
      type: [Object, String],
      default: (): ProjectionLike =>  {
        const lambertProjection = new Projection({
          code: "EPSG:31370",
          extent: projectionExtent,
          units: "m",
          getPointResolution: (resolution, point) => {
            return (resolution / 6) * 5;
          }
        });
        addProjection(lambertProjection);
        return lambertProjection;
      },
    },
  },
  setup(props, { emit, attrs }) {

    const mapScaleId = `ippro-map-scale-${Math.random()
        .toString(36)
        .substring(7)}`;
    const mapMousePositionId = `ippro-map-mouse-position-${Math.random()
        .toString(36)
        .substring(7)}`;
    const olMapId = `vl-ol-map-id-${Math.random()
        .toString(36)
        .substring(7)}`;
    const refMap = `ippro-map-ref-${Math.random()
        .toString(36)
        .substring(7)}`;
    const refLayerToggle = `ippro-map-ref-layer-toggle-${Math.random()
        .toString(36)
        .substring(7)}`;

    const source = new VectorSource();
    const vector = new VectorLayer({
      source: source,
      style: defaultStyle.default
    });
    const selectClick: Select = new Select({
      condition: click,
      multi: true,
      layers: [vector],
      style: null
    });

    let _olMapRef: { olMap: Map };
    const _setOlMapRef = () => {
      // see: https://stackoverflow.com/questions/66013172/vue2-composition-api-dynamic-template-refs
      // vue2.7 = getCurrentInstance()?.proxy?.$refs
      // vue3??? = setup(props, {refs})
      _olMapRef = getCurrentInstance()?.proxy?.$refs[refMap] as any;
      if (!_olMapRef) {
        // probably called after onMounted
        // (om 1 of andere reden kan de ref niet meer goed gevonden worden na onMounted = cachen)
        throw new Error(`vl-ippro-map: unable to find refMap '${refMap}'`);
      }
    }
    const _getOlMap = (): Map => {
      if (_olMapRef && _olMapRef.olMap) {
        return _olMapRef.olMap;
      }
      return null;
    }

    const currentLayerId = ref(props.defaultLayerId); // TODO ?? onMounted ??
    const fullscreen = ref(false);
    const hoveredFeature = ref<Feature>(null);
    const hoveredFeatureIndex = ref<number>(null);
    const tiledLayers = ref<IpproMapLayer[]>([]);
    const inZoomAnimation = ref(false);
    const currentBackgroundColor = ref<string>(null);

    const instance = computed(() => {
      return _getOlMap();
    });

    const classes = computed(() => {
      return [
        "ippro-map",
        {
          "ippro-map--fullscreen": fullscreen.value
        }
      ];
    });

    const zoomLevel = computed(() => {
      return _getOlMap().getView().getZoom();
    });

    const layers = computed((): IpproMapLayer[] => {
      return tiledLayers.value
          .filter(
              (layer: IpproMapLayer) =>
                  layer.id === props.defaultLayerId || props.modLayers.includes(layer.id)
          )
          .map((layer: IpproMapLayer) => ({
            ...layer,
            active: layer.id === currentLayerId.value
          }));
    });

    const defaultLayer = computed((): IpproMapLayer => {
      if (layers.value && props.defaultLayerId) {
        return layers.value.filter(layer => layer.id === props.defaultLayerId)[0];
      }
      return null;
    });

    const features = computed((): Feature[] => {
      return source ? source.getFeatures() : null;
    });

    const mapStyle = computed((): any => {
      let color: string = currentBackgroundColor.value;
      if (!color) {
        color = props.backgroundColor;
      }
      if (!color) {
        color = "#e8ebee";
      }

      return {
        backgroundColor: color
      }
    });

    const setLayer = (layer: IpproMapLayer) => {
      if (props.layerSettings.length) {
        const layerSettings = props.layerSettings.find(
            layerSetting => layerSetting.layerId === layer.id
        );
        if (layerSettings.opacity) {
          layer.tile.setOpacity(layerSettings.opacity);
        }
        currentBackgroundColor.value = layerSettings.backgroundColor
            ? layerSettings.backgroundColor
            : null;
      }
      _getOlMap().getLayers().setAt(0, layer.tile);
    }

    const toggleFullscreen = (newValue: boolean) => {
      if (fullscreen.value !== newValue) {
        emit('toggle-full-screen', newValue);
      }
      fullscreen.value = newValue;
      Vue.nextTick(() => {
        _getOlMap().updateSize();
      });
    }

    const zoomIn = (event: MouseEvent) => {
      if (!inZoomAnimation.value || !props.modAbsoluteZoom) {
        inZoomAnimation.value = true;
        _getOlMap().getView().animate(
            {
              zoom: _getOlMap().getView().getZoom() + 1
            },
            () => {
              inZoomAnimation.value = false;
            }
        );
      }
    }

    const zoomOut = (event: MouseEvent) => {
      if (!inZoomAnimation.value || !props.modAbsoluteZoom) {
        inZoomAnimation.value = true;
        _getOlMap().getView().animate(
            {
              zoom: _getOlMap().getView().getZoom() - 1
            },
            () => {
              inZoomAnimation.value = false;
            }
        );
      }
    }

    const zoomToFeature = (feature: Feature, duration: number) => {
      zoomToExtent(feature.getGeometry().getExtent(), duration);
    }

    const zoomToCoordinates = (coordinates: number[], duration?: number, zoom?: number) => {
      if (_getOlMap()) {
        _getOlMap().getView().animate({
          center: coordinates,
          zoom: zoom ? zoom : 12,
          duration: duration ? duration : 500
        });
      }
    }

    const zoomToExtent = (extent?: Extent, duration?: number) => {
      if (_getOlMap()) {
        _getOlMap().getView().fit(extent, {
          size: _getOlMap().getSize(),
          duration: duration ? duration : 500
        });
      }
    }

    const zoomToFlandersExtent = () => {
      const flandersExtent: Extent = boundingExtent([
        [22000, 153000],
        [259000, 245000]
      ]);
      const flandersCenter = getCenter(flandersExtent);
      _getOlMap().getView().setZoom(props.zoom);
      _getOlMap().getView().setCenter(flandersCenter);
    }

    const zoomToZone = (duration: number = 500, mySource?: VectorSource) => {
      const extent = mySource ? mySource.getExtent() : source.getExtent();
      if (extent.filter(isFinite).length === 4) {
        zoomToExtent(extent, duration);
      }
    }

    const showMousePosition = () => {
      if (_getOlMap()) {
        const mousePositionControl = new MousePosition({
          coordinateFormat: createStringXY(2),
          target: mapMousePositionId
              ? (document.getElementById(mapMousePositionId) as HTMLElement)
              : undefined,
          undefinedHTML: " "
        });
        _getOlMap().addControl(mousePositionControl);
      }
    }

    const updateSize = () => {
      _getOlMap().updateSize();
    }

    const showScale = () => {
      if (_getOlMap()) {
        const scaleLineControl = new ScaleLine({
          units: "metric",
          target: mapScaleId
              ? (document.getElementById(mapScaleId) as HTMLElement)
              : undefined
        });
        _getOlMap().addControl(scaleLineControl);
      }
    }

    const showMapInfo = () => {
      showMousePosition();
      showScale();
    }

    const _stringToWkt = (string: string) => {
      return new WKT().readFeature(string);
    }

    const addFeature = (
        feature: Feature,
        style: Style,
        mySource: VectorSource,
        id: string | number | undefined = undefined
    ) => {
      const featureGeometry = feature.getGeometry();
      const geometries: Geometry[] =
          featureGeometry instanceof GeometryCollection
              ? (featureGeometry as GeometryCollection).getGeometries()
              : [featureGeometry];
      geometries.forEach((geometry: Geometry) => {
        const feature: Feature = new Feature({
          geometry
        });
        if (style) {
          feature.setStyle(style);
        }
        if (id) {
          feature.setId(id);
        }
        mySource.addFeature(feature);
      });

      emit("feature-added", feature);
      if (props.modFitToWkt) {
        Vue.nextTick(() => {
          zoomToZone(10);
        });
      }
    }

    const setFeatures = () => {
      if (props.value && props.value.length) {
        props.value.forEach(zone => {
          try {
            addFeature(
                _stringToWkt(zone.geometry as string),
                zone.style,
                source,
                zone.meta ? zone.meta.id : undefined
            );
          } catch {
            emit("read-wkt-failed", zone.geometry);
          }
        });
      }
    }

    const removeFeature = (feature: Feature, mySource: VectorSource) => {
      mySource.removeFeature(feature);
    }


    const initMap = () => {
      _setOlMapRef();

      tiledLayers.value = registeredLayers.map(layer => ({
        ...layer,
        tile: new Tile(layer.tileTemplate)
      }));

      if (_getOlMap()) {
        _getOlMap().setView(
            new View({
              projection: props.projection,
              zoom: props.zoom,
              center: props.center,
              minZoom: props.minZoom,
              maxZoom: props.maxZoom,
              resolutions: props.resolutions,
              constrainResolution: props.constrainResolution,
              enableRotation: props.enableRotation
            } as IpproViewOptions)
        );
        if (defaultLayer.value) {
          setLayer(defaultLayer.value);
        }
        _getOlMap().addLayer(vector);
        setFeatures();
        // _getOlMap().on("pointerdrag", () => {
        //   // TODO
        //   throw new Error('TODO: pointerdrag');
        //   // if (document.activeElement !== map.$el) {
        //   //   (map.$el as HTMLElement).focus();
        //   // }
        // });
        _getOlMap().addInteraction(selectClick);
        selectClick.on("select", e => {
          emit("features-selected", e.target.getFeatures());
        });
        _getOlMap().on("click", e => {
          emit("click", e);
          const clickedFeatures: Feature[] = [];
          _getOlMap().forEachFeatureAtPixel(e.pixel, feature => {
            clickedFeatures.push(feature as Feature);
          });
          if (clickedFeatures.length) {
            emit("features-clicked", clickedFeatures, e);
          }
        });
        _getOlMap().on("pointermove", e => {
          const features = _getOlMap().forEachFeatureAtPixel(
              e.pixel,
              (feature: FeatureLike) => {
                hoveredFeature.value = feature as Feature;
                return true;
              }
          );
          if (!features) {
            hoveredFeature.value = null;
          }
        });
        _getOlMap().on("moveend", () => {
          emit(
              "view-updated",
              _getOlMap().getView().calculateExtent(_getOlMap().getSize())
          );
        });
      }
    }

  watch(
      hoveredFeature,
      (newValue, oldValue) => {
        if (hoveredFeature.value !== oldValue) {
          if (hoveredFeature.value) {
            emit("mouseover", hoveredFeature);
          }
          if (oldValue) {
            emit("mouseout", oldValue);
          }
        }
      },
      { immediate: false, deep: true });


  watch(
      () => props.value,
      () => {
        source.clear();
        setFeatures();
      },
      { immediate: false, deep: true })

    onMounted(() => {
      initMap();
      if (props.modZoomToFlandersOnInit) {
        zoomToFlandersExtent();
      }
      showMapInfo();
      toggleFullscreen(props.modFullscreen);
    })

    return {
      mapScaleId,
      mapMousePositionId,
      olMapId,
      refMap,
      refLayerToggle,

      currentLayerId,
      fullscreen,
      hoveredFeature,
      hoveredFeatureIndex,
      tiledLayers,
      inZoomAnimation,
      currentBackgroundColor,

      instance,
      classes,
      zoomLevel,
      layers,
      defaultLayer,
      features,
      mapStyle,

      setLayer,
      toggleFullscreen,
      zoomIn,
      zoomOut,
      zoomToFeature,
      zoomToCoordinates,
      zoomToExtent,
      zoomToFlandersExtent,
      zoomToZone,
      showMousePosition,
      updateSize,
      showScale,
      showMapInfo,
      addFeature,
      setFeatures,
    }
  }
})
