import {computed, defineComponent} from 'vue';

export default defineComponent({
    name: 'KlProfileCard',
    props: {
        title: { type: String, required: true },
        subtitle: { type: String, default: undefined },
        titleTag: { type: String, default: 'span' },
    },
    setup(props, { slots }) {

        const classes = computed(() => {
            return ['kl-profile-card'];
        });

        const headerClasses = computed(() => {
            return [
                'kl-profile-card__header', {
                    'kl-profile-card__header--bordered': slots.default,
                }];
        });

        const hasDefaultSlot = computed(() => !!slots.default);

        return {
            classes, headerClasses, hasDefaultSlot,
        }
    }
})
