import {computed, defineComponent, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {VlMapToolsAction} from "@govflanders/vl-ui-vue-components";

export default defineComponent({
  name: 'IpproMapFullscreen',
  components: { VlMapToolsAction },
  emits: ['change'],
  props: {
    initActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const active = ref(false);

    const fullscreenIcon = computed(() => active.value ? "shrink" : "expand");
    const fullscreenLabel = computed(() => active.value ? "Sluit&nbsp;volledig&nbsp;scherm" : "Volledig&nbsp;scherm");

    const toggleFullscreen = () => active.value = !active.value;

    const _fullscreenKeyboardHandler = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        active.value = false;
      }
    }

    watch(
        active,
        (newValue) => {
          emit('change', newValue);
        });


    watch(
        () => props.initActive,
        (newValue) => {
          active.value = newValue;
        });

    onMounted(() => {
      window.addEventListener("keyup", _fullscreenKeyboardHandler);
      if (props.initActive) {
        active.value = true;
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keyup", _fullscreenKeyboardHandler);
    });

    return {
      active,
      fullscreenIcon,
      fullscreenLabel,
      toggleFullscreen,
    }
  }
})
