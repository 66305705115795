import Vue from 'vue';
import router from '../router/router';
import {KlRouteConfig} from "@/router/router-vue";

export interface IMetaHandler {
    setPageTitle(title: string, parentTitle?: string): void;
    setParent(route: KlRouteConfig): void;
}

export const MetaHandler: IMetaHandler = {
    async setPageTitle(title: string, parentTitle?: string) {
        if (router && router.app && router.app.$route) {
            const route = router.app.$route;
            if (title && route && route.meta && route.meta.breadcrumb && route.meta.breadcrumb.title) {
                const parent = route.meta.breadcrumb.parent;
                route.meta.breadcrumb.title = title.replace(/'/g, '`');

                // if we don't do a router replace, the breadcrumb component will not be notified of the change.
                await router.replace({ query: { ...route.query, temp: Date.now().toString() }, hash: route.hash });
                await router.replace({ query: { ...route.query, temp: undefined }, hash: route.hash });

                if (parentTitle && parent && parent.meta.breadcrumb && parent.meta.breadcrumb.title) {
                    parent.meta.breadcrumb.title = parentTitle.replace(/'/g, '`');
                }
            }

        }
    },
    setParent(parentRoute: KlRouteConfig) {
        if (router && router.app && router.app.$route) {
            const route = router.app.$route;
            if (route && route.meta && route.meta.breadcrumb && route.meta.breadcrumb.title) {
               route.meta.breadcrumb.parent = parentRoute;
            }
        }
    }
};

export default {
    install(vue: typeof Vue): void {
        vue.prototype.$meta = MetaHandler;
    },
};

export function useMeta(): IMetaHandler {
    return MetaHandler;
}
