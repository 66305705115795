export class IMailSettings {
    confirmation: boolean;
    confirmationExampleUri: string;
    reminder1DayToRespond: boolean;
    reminder1DayToRespondExampleUri: string;
    uploadImklSuccess: boolean;
    uploadImklSuccessExampleUri: string;
    uploadImklFailed: boolean;
    uploadImklFailedExampleUri: string;
    respondedNotInvolved: boolean;
    respondedNotInvolvedExampleUri: string;
}