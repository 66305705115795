import { isEmpty } from "lodash";

export enum RegistrationType {
    Organisation = 0,
    Citizen = 1,
}

export class RegistrationModel {
    private _registrationType: RegistrationType;
    private _activeStep: number;
    private _organisationCode: string;
    private _isLegalRepresentative: boolean;
    private _roles: Array<string>;

    constructor() {
        this._activeStep = 1;
        this._registrationType = RegistrationType.Organisation;
        this._organisationCode = '';
        this._isLegalRepresentative = false;
        this._roles = new Array<string>();
    }

    get registrationType(): RegistrationType {
        return this._registrationType;
    }

    get activeStep(): number {
        return this._activeStep;
    }

    get roles(): Array<string> {
        return this._roles;
    }

    get isFinalStep(): boolean {
        if (this._registrationType === RegistrationType.Citizen && this._activeStep === 2) {
            return true;
        }
        return this._activeStep === 4;
    }

    get organisationCode(): string {
        return this._organisationCode;
    }

    get isLegalRepresentative(): boolean {
        return this._isLegalRepresentative;
    }

    isStepValid(): boolean {
        if (this.activeStep === 1) return true;
        if (this.activeStep === 2 && this.registrationType === RegistrationType.Organisation) {
            return !!this._organisationCode;
        }
        if (this.activeStep === 3 && this.registrationType === RegistrationType.Organisation) {
            return !isEmpty(this._roles);
        }
    }

    nextStep() {
        this._activeStep += 1;
    }

    previousStep() {
        this._activeStep -= 1;
    }

    setRegistrationType(type: RegistrationType) {
        this._registrationType = type;
    }

    setRoles(roles: Array<string>) {
        this._roles = roles;
    }

    setOrganisationCode(code: string) {
        this._organisationCode = code;
    }

    setLegalRepresentative(isLegalRepresentative: boolean) {
        this._isLegalRepresentative = isLegalRepresentative;
    }
}