import {defineComponent, inject, onMounted, ref} from "vue";
import * as KlipApi from "@/api/klip-api.proxy";
import dayjs from "dayjs";

export default defineComponent({
    methods: {dayjs},
    setup() {
        const proxy = inject<KlipApi.IKlipApiProxy>('klipApiProxy');
        const viewModelLoading = ref<boolean>(false);
        const holidays = ref< { [key: string]: string[]; }>(null);
        const loadHolidays = async () => {
            const response = await proxy.operations_Holidays();
            if (response.isSuccessStatusCode) {
                holidays.value = response.result;
            }
            viewModelLoading.value = false;
        };

        onMounted(async () => {
            await loadHolidays();
        });

        return {
            viewModelLoading,
            holidays,
        }
    }
});
