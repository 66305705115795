import { httpClient } from '@/app/shared/service/http-client';
import { AxiosError } from 'axios';

export interface ILogEvent {
    logEventLevel: LogLevel;
    logImpact: Impact;
    fileName: string;
    functionName: string;
    id: string;
    context: string;
    detailContext: string;
    error: ILogEntryError | any;
    correlationId: string;
}

export interface ILogEntryError {
    message?: string | undefined;
    baseUrl?: string | undefined;
    method?: string | undefined;
    url?: string | undefined;
    code?: string | undefined;
    status?: number;
}

export enum LogLevel {
    Trace = 0,
    Debug = 1,
    Information = 2,
    Warning = 3,
    Error = 4,
    Critical = 5,
    None = 6,
}
export enum Impact {
    Low = 0,
    Medium = 1,
    High = 2,
}

export class Logger {
    public static send(logEvent: ILogEvent, showInClient = false) {
        httpClient.post('/bff/log/add', logEvent, {
            headers: {
                'X-Correlation-Id': logEvent.correlationId,
            },
        });
        logEvent.error = parseError(logEvent.error);

        if (showInClient) {
            switch (logEvent.logEventLevel) {
                case LogLevel.Debug:
                case LogLevel.None:
                case LogLevel.Trace:
                    // tslint:disable-next-line: no-console
                    console.log('KLIP: %o', logEvent);
                    break;
                case LogLevel.Warning:
                case LogLevel.Information:
                    // tslint:disable-next-line: no-console
                    console.warn('KLIP: %o', logEvent);
                    break;
                case LogLevel.Error:
                case LogLevel.Critical:
                    // tslint:disable-next-line: no-console
                    console.error('KLIP: %o', logEvent);
                    break;
            }
        }
    }
}

function parseError(error: AxiosError | Error) {
    const message = error.message;
    const name = error.name;
    let data: any = '';
    let extra = '';

    if ((error as AxiosError).response) {
        data = (error as AxiosError).response.data;
    }
    if ((error as AxiosError).request) {
        extra = (error as AxiosError).request.responseURL;
    }

    return JSON.stringify({ message, name, data, extra });
}
