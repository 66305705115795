import { KlRouteConfig } from '@/router/router-vue';
import { pdaIndex, pdaZoneCreate, pdaZoneId, pdaZoneEdit, pdaMapRequest } from './pda-routes';
import { settings } from '../settings/settings-routes';

const oldRoutes: KlRouteConfig[] = [
    { path: '/pdazoneoverview', redirect: pdaIndex },
    { path: '/newpdazone', redirect: pdaZoneCreate },
    { path: '/pdazone/:id', redirect: pdaZoneId },
    { path: '/pdazone_edit/:id', redirect: pdaZoneEdit },
    { path: '/pdamaprequest/:id', redirect: pdaMapRequest },
    { path: '/pdaprofile', redirect: settings },
];

export default oldRoutes;
