import {Component, Vue, Prop, Ref, Inject} from "vue-property-decorator";
import { Extent, boundingExtent } from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import {
  CoordinateParserService,
  CoordinateParserServiceFactory, ECoordinateType
} from '@/app/shared/service/coordinate-parser/coordinate-parser-service';
import {isEmpty} from "lodash-es";
import {AxiosStatic} from 'axios';
import IpproAutocomplete from '@/app/shared/components/ippro-autocomplete';
import {defineComponent, getCurrentInstance, inject, onMounted, ref} from 'vue';
import {AutoCompleteResult} from '@/app/shared/components/ippro-autocomplete/ippro-autocomplete';

export interface LocationResponse {
  Thoroughfarename: string;
  Housenumber: string;
  Municipality: string;
  FormattedAddress: string;
  BoundingBox: {
    LowerLeft: {
      Lat_WGS84: number;
      Lon_WGS84: number;
      X_Lambert72: number;
      Y_Lambert72: number;
    };
    UpperRight: {
      Lat_WGS84: number;
      Lon_WGS84: number;
      X_Lambert72: number;
      Y_Lambert72: number;
    };
  };
}

export enum ESearchType {
  Address = 'address',
  Coordinate = 'coordinate',
  Parcel = 'parcel',
}

export default defineComponent({
    name: 'KlMapSearch',
    components: {
        IpproAutocomplete
    },
    emits: ['input', 'change', 'submit', 'extent-selected', 'coordinate-selected'],
    props: {
        value: {
            type: String,
            default: ``,
        },
        addressSuggestionUrl: {
            type: String,
            default: `https://geo.api.vlaanderen.be/geolocation/Suggestion?q=:keyword&c=5`,
        },
        addressSearchUrl: {
            type: String,
            default: `https://geo.api.vlaanderen.be/geolocation/Location?q=:keyword&c=1`,
        },
        parcelSuggectionUrl: {
            type: String,
            default: `https://geo.api.vlaanderen.be/geolocation/perceel/suggestion?q=:keyword&c=5&data=adp&status=actual`,
        },
        parcelSearchUrl: {
            type: String,
            default: `https://geo.api.vlaanderen.be/capakey/v2/parcel/:keyword?geometry=full&data=adp&status=actual`,
        },
        minLength: {
            type: Number,
            default: 3,
        },
    },
    setup(props, { emit }) {

        const autocomplete = ref(null); // = ippro-autocomplete
        const axiosClient = inject<AxiosStatic>('axiosClient', null);

        const data = ref<AutoCompleteResult[]>([]);
        const fetching = ref(false);
        const searchField = ref('');

        const _coordinateParser: CoordinateParserService = CoordinateParserServiceFactory();

        let _coordinateData: AutoCompleteResult;
        let _addressData: AutoCompleteResult[];
        let _parcelData: AutoCompleteResult[];

        const _mergeData = (): AutoCompleteResult[] => {
            let result = [];
            if (_coordinateData) {
                result.push(_coordinateData);
            }
            if (!isEmpty(_addressData)) {
                result = result.concat(_addressData);
            }
            if (!isEmpty(_parcelData)) {
                result = result.concat(_parcelData);
            }
            return result;
        }

        const onInput = () => {
            emit("input", searchField.value);
            _handleInput().then();
        }

        const _handleInput = async () => {
            if (isEmpty(searchField.value)) {
                data.value = [];
                return;
            }
            if (searchField.value.length <= props.minLength) {
                return;
            }

            _coordinateData = _parseCoordinate(searchField.value);

            // opt: only continue search when input cannot be parsed to a coordinate
            if (isEmpty(_coordinateData)) {
                _addressData = await _fetchAddressSuggestions(searchField.value);
                _parcelData = await _fetchParcelSuggestions(searchField.value);
            } else {
                _addressData = null;
                _parcelData = null;
            }

            data.value = _mergeData();
        }

        const onSelect = (item: { name: string, type: ESearchType }) => {
            if (!item?.name) {
                return;
            }

            emit("change", item.name);
            searchField.value = item.name;

            if (item.type === ESearchType.Address) {
                _onSelectAddress(item.name);
            }
            if (item.type === ESearchType.Coordinate) {
                _onSelectCoordinate(item.name);
            }
            if (item.type === ESearchType.Parcel) {
                _onSelectParcel(item.name);
            }
        }

        const focusSearch = () => {

            autocomplete.value.focus();

            // TODO: investigate
            // without the setTimeout: for some strange reason, the dropdown will not be shown here for coordinates
            setTimeout(() => {
                _handleInput().then(); // refresh search on previous input
            }, 0);
        }

        const onEnter = (e: Event) => {
            emit("submit", e);
            e.preventDefault();
        }

        const _parseCoordinate = (input: string): AutoCompleteResult => {
            const parsedCoordinate = _coordinateParser.parseCoordinate(input);
            if (!parsedCoordinate) {
                return null;
            }

            const formatttedCoordinate = _coordinateParser.formatCoordinate(parsedCoordinate);
            const coordinateTypeLabelMap = {};
            coordinateTypeLabelMap[ECoordinateType.lambert72] = 'Lambert 72 coördinaat';
            coordinateTypeLabelMap[ECoordinateType.wgs84] = 'WGS84 coördinaat';

            const label = coordinateTypeLabelMap[parsedCoordinate.spatialReference.wkid];

            return {
                title: label ? `<span>${label}:</span><br/><span class="vl-u-text--bold">${formatttedCoordinate}</span>` : formatttedCoordinate,
                // title: label ? `${label}: ${formatttedCoordinate}` : formatttedCoordinate,
                value: {
                    type: ESearchType.Coordinate,
                    name: formatttedCoordinate
                }
            };
        }

        const _fetchAddressSuggestions = async (input: string): Promise<AutoCompleteResult[]> => {
            if (!props.addressSuggestionUrl) {
                return null;
            }
            if (isEmpty(input)) {
                return null;
            }

            fetching.value = true;
            const apiUrl = props.addressSuggestionUrl.replace(":keyword", input.trim());
            const response = await _getInjectedAxiosClient().get(apiUrl);
            fetching.value = false;

            if (response && response.data && response.data.SuggestionResult) {
                const autoCompleteResult: AutoCompleteResult[] = response.data.SuggestionResult.map((item: string) => ({
                    title: item,
                    value: {
                        type: ESearchType.Address,
                        name: item
                    }
                }));
                return autoCompleteResult;
            }

            return null;
        }

        const _fetchAddressExtent = async (address: string): Promise<Extent> => {
            if (!props.addressSearchUrl) {
                return null;
            }
            if (isEmpty(address)) {
                return null;
            }

            fetching.value = true;
            const apiUrl = props.addressSearchUrl.replace(":keyword", address);
            const response = await _getInjectedAxiosClient().get(apiUrl);
            fetching.value = false;

            if (response?.data?.LocationResult) {
                const locationResult: LocationResponse = response.data.LocationResult[0];
                const coordinates = locationResult.BoundingBox;
                const extent: Extent = boundingExtent([
                    [
                        coordinates.LowerLeft.X_Lambert72,
                        coordinates.LowerLeft.Y_Lambert72
                    ],
                    [
                        coordinates.UpperRight.X_Lambert72,
                        coordinates.UpperRight.Y_Lambert72
                    ]
                ]);
                return extent;
            }
            return null;
        }

        const _fetchParcelSuggestions = async (input: string): Promise<AutoCompleteResult[]> => {
            if (!props.parcelSuggectionUrl) {
                return null;
            }
            if (isEmpty(input)) {
                return null;
            }

            fetching.value = true;
            const apiUrl = props.parcelSuggectionUrl.replace(":keyword", input.trim());
            const response = await _getInjectedAxiosClient().get(apiUrl);
            fetching.value = false;

            if (response && response.data && response.data.SuggestionResult) {
                const autoCompleteResult: AutoCompleteResult[] = response.data.SuggestionResult.map((item: string) => ({
                    title: `Perceel: ${item}`,
                    value: {
                        type: ESearchType.Parcel,
                        name: item
                    }
                }));
                return autoCompleteResult;
            }

            return null;
        }

        const _fetchParcelExtent = async (capakey: string): Promise<Extent> => {
            if (!props.parcelSearchUrl) {
                return null;
            }
            if (isEmpty(capakey)) {
                return null;
            }

            fetching.value = true;
            const apiUrl = props.parcelSearchUrl.replace(":keyword", capakey);
            const response = await _getInjectedAxiosClient().get(apiUrl);
            fetching.value = false;

            if (response?.data?.geometry) {
                const bbox = new GeoJSON().readFeatures(response.data.geometry.boundingBox)
                const extent = bbox[0].getGeometry().getExtent()
                return extent;
            }
            return null;
        }

        const _onSelectAddress = async (address: string) => {
            const extent = await _fetchAddressExtent(address);
            if (extent) {
                emit('extent-selected', extent);
            }
        }

        const _onSelectParcel = async (capakey: string) => {
            const extent = await _fetchParcelExtent(capakey);
            if (extent) {
                emit('extent-selected', extent);
            }
        }

        const _onSelectCoordinate = (coordinateString: string) => {
            const parsed = _coordinateParser.parseCoordinate(coordinateString);
            const lambert72 = _coordinateParser.convertCoordinateToLambert72(parsed);
            emit('coordinate-selected', lambert72);
        }

        const _getInjectedAxiosClient = () => {
            return axiosClient;
        }

        onMounted(() => {
            autocomplete.value = getCurrentInstance()?.proxy?.$refs['autocomplete'];

            // TODO
            searchField.value = props.value || '';
        })

        return {
            data,
            fetching,
            searchField,

            onInput,
            onSelect,
            focusSearch,
            onEnter,
        }
    }
})
