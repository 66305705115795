import {defineComponent, toRefs, ref, watch, computed} from "vue";
import { AuditLog } from '@/api/klip-api.proxy';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

export default defineComponent({
    props: {
        auditLogs: {
            type: Array<AuditLog>,
            default: [],
            required: true
        } 
    },
    setup(props) {
        const logs = ref<AuditLog[]>([]);
        const logsChanged = (auditLogs: Array<AuditLog>) => {
            logs.value = auditLogs;
        };

        const formatDate = (timestamp: string) => {
            return dayjs(timestamp).format('DD/MM/YYYY - HHumm')
        };

        const hasLogs = computed(() => {
            return !isEmpty(logs.value);
        });
        
        watch(props.auditLogs, logsChanged, { immediate: true, deep: true });

        return {
            logs,
            hasLogs,
            formatDate
        };
    }
});
