import {camelCase, upperFirst} from 'lodash-es';

export default {
    install(app) {

        const componentFiles = import.meta.glob(
            '@/app/**/kl-*.vue',
            { eager: true }
        );

        Object.entries(componentFiles).forEach(([path, componentConfig]) => {
            const componentName = upperFirst(
                camelCase(path.split('/').pop().replace(/\.\w+$/, ''))
            );

            app.component(
                `${componentName}`, (componentConfig as any).default
            );
        });
    },
};
