import {computed, defineComponent} from 'vue';

export default defineComponent({
  name: 'IpproPagination',
  emits: ['page-change'],
  props: {
    pageSize: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      required: true,
    },
    results: {
      type: Number,
      required: true,
    },
    locale: {
      type: String,
      default: 'nl',
      required: false,
    },
  },
  setup(props, { emit }) {

    const messages: { [key: string]: any } = {
      en: {
        of: "of",
        next: "Next",
        previous: "Previous",
        goToPrevious: "Go to the previous page",
        goToNext: "Go to the next page",
        goToPage: "Go to page"
      },
      nl: {
        of: "van",
        next: "Volgende",
        previous: "Vorige",
        goToPrevious: "Ga naar de vorige pagina",
        goToNext: "Ga naar de volgende pagina",
        goToPage: "Ga naar pagina"
      }
    };

    const translation = (key: string): string => {
      return messages[messages[props.locale] ? props.locale : "nl"][key];
    }

    const pagerFrom = computed<number>((): number => {
      return props.currentPage > 1
          ? (props.currentPage - 1) * (props.pageSize || props.results) + 1
          : 1;
    });

    const pagerTo = computed<number>((): number => {
      return Math.min(
          props.total ? props.total : props.results * props.currentPage,
          props.pageSize * props.currentPage
      );
    });

    const pagerTotal = computed<number>((): number => {
      return props.total || (props.results < props.pageSize ? props.results : 0);
    });

    const pagePosition = computed<string>((): string => {
      return `<span><strong>${pagerFrom.value}&nbsp;-&nbsp;${pagerTo.value ||
      "..."}</strong> ${translation("of")}&nbsp;${pagerTotal.value ||
      "..."}</span>`;
    });

    const pageCount = computed<number>((): number => {
      return Math.ceil(props.total / (props.pageSize || 1));
    });

    const prevButtonActive = computed<boolean>((): boolean => {
      return props.currentPage !== 1;
    });

    const nextButtonActive = computed<boolean>((): boolean => {
      return props.currentPage !== pageCount.value;
    });

    const pagination = computed<string[]>((): string[] => {
      const pageArray: string[] = [];

      if (pageCount.value > 1) {
        if (pageCount.value < 7) {
          for (let i = 1; i <= pageCount.value; i++) {
            pageArray.push(i.toString());
          }
        } else {
          for (let i = 1; i <= 7; i++) {
            switch (i) {
              case 1:
                pageArray.push("1");
                break;
              case 2:
                if (props.currentPage <= 4) {
                  pageArray.push("2");
                } else {
                  pageArray.push("...");
                }
                break;
              case 3:
                if (props.currentPage <= 4) {
                  pageArray.push("3");
                } else if (pageCount.value - props.currentPage < 4) {
                  pageArray.push((pageCount.value - 4).toString());
                } else {
                  pageArray.push((props.currentPage - 1).toString());
                }
                break;
              case 4:
                if (props.currentPage <= 4) {
                  pageArray.push("4");
                } else if (pageCount.value - props.currentPage < 4) {
                  pageArray.push((pageCount.value - 3).toString());
                } else {
                  pageArray.push(props.currentPage.toString());
                }
                break;
              case 5:
                if (props.currentPage <= 4) {
                  pageArray.push("5");
                } else if (pageCount.value - props.currentPage < 4) {
                  pageArray.push((pageCount.value - 2).toString());
                } else {
                  pageArray.push((props.currentPage + 1).toString());
                }
                break;
              case 6:
                if (pageCount.value - props.currentPage < 4) {
                  pageArray.push((pageCount.value - 1).toString());
                } else {
                  pageArray.push("...");
                }
                break;
              case 7:
                pageArray.push(pageCount.value.toString());
                break;
            }
          }
        }
      }
      return pageArray;
    });

    const changePage = (pageNumber: number) => {
      if (!isNaN(pageNumber)) {
        emit('page-change', pageNumber);
      }
    }

    return {
      translation,
      pagerFrom,
      pagerTo,
      pagerTotal,
      pagePosition,
      pageCount,
      prevButtonActive,
      nextButtonActive,
      pagination,
      changePage,
    }
  }
})
