import { VNode } from 'vue';
import { DirectiveBinding } from 'vue/types/options';

const HANDLER = '_vue_click_outside_handler';

// prevent global header/footer from cancelling this click event
function watchElements(): HTMLElement[] {
  const elements: any[] = [document.documentElement];
  for (const el of document.getElementsByClassName('iw2-wrapper')) {
    elements.push(el);
  }
  return elements;
}

function bind(el: any, binding: DirectiveBinding, vnode: VNode) {
  unbind(el);
  const vm = vnode.context;
  const callback = binding.value;
  let initialMacrotaskEnded = false;
  setTimeout(() => {
    initialMacrotaskEnded = true;
  }, 0);

  el[HANDLER] = (ev: any) => {
    const path = ev.path || (ev.composedPath ? ev.composedPath() : undefined);
    if (initialMacrotaskEnded && (path ? path.indexOf(el) < 0 : !el.contains(ev.target))) {
      return callback.call(vm, ev);
    }
  };
  watchElements().forEach((element) => {
    element.addEventListener('click', el[HANDLER], false);
  });
}

function unbind(el: any) {
  watchElements().forEach((element) => {
    element.removeEventListener('click', el[HANDLER], false);
  });
  delete el[HANDLER];
}

const KlClickOutside = {
  bind,
  update: (el: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
    if (binding.value === binding.oldValue) { return; }
    bind(el, binding, vnode);
  },
  unbind,
  // bind: (el: HTMLElement) => {
  //   document.documentElement.addEventListener('click', el[HANDLER], false);
  // },
};

export default KlClickOutside;
