import KlDescriptionListRow from './components/kl-description-list-row/kl-description-list-row.vue';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'KlDescriptionList',
    components: { KlDescriptionListRow },
    props: {},
    setup(props) {
    }
})
