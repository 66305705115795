import { KlRouteConfig } from '@/router/router-vue';
import { klipIndex } from '@/router/router';

export const ImklTest: KlRouteConfig = {
    path: '/imkltest',
    name: 'imkltest',
    component: () => import(/* webpackChunkName: "imkltest" */ './imkltest.vue'),
    meta: {
        breadcrumb: { title: 'IMKL-pakket testen', parent: klipIndex },
    },
};

export const ImklTestResult: KlRouteConfig = {
    path: '/imkltest/:id?',
    name: 'imkltestresult',
    component: () => import(/* webpackChunkName: "imkltest" */ './imkltestresult.vue'),
    meta: {
        breadcrumb: { title: 'IMKL-pakket testresultaat', parent: klipIndex },
        allowAnonymous: true,
    },
};

export const ImklTestViewer: KlRouteConfig = {
    path: '/imkltest/:imklFileId/map',
    name: 'imkltestviewer',
    component: () => import('./../viewer/index.vue'),
    meta: {
        breadcrumb: { title: 'Leidingviewer', parent: klipIndex },
        allowAnonymous: true,
    },
};

export default [
    ImklTest,
    ImklTestResult,
    ImklTestViewer,
];
