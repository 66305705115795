import Vue from 'vue';
import VlUiVueComponents from '@govflanders/vl-ui-vue-components'
import "@govflanders/vl-ui-pill-input";
import Multiselect from 'vue-multiselect';
import VueJsonPretty from 'vue-json-pretty'

export default {
    install(vue: typeof Vue): void {

        // ref: https://overheid.vlaanderen.be/webuniversum/v3/vue-documentation/?path=/docs/about-getting-started--page
        vue.use(VlUiVueComponents);
        Vue.component("vue-json-pretty", VueJsonPretty)
        vue.component('multiselect', Multiselect);
    },
};
