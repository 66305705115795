import { IpproMapLayer } from "../../vl-ippro-map-layers";
import {defineComponent} from 'vue';
import { VlMapBaseLayer, VlMapBaseLayerToggle } from "@govflanders/vl-ui-vue-components";

export default defineComponent({
  name: 'IpproMapLayerToggle',
  components: { VlMapBaseLayer, VlMapBaseLayerToggle },
  emits: ['switch-layer'],
  props: {
    layers: {
      type: Array as () => IpproMapLayer[],
      default: () => [],
    },
  },
  setup(props, {emit}) {
    const changeLayer = (layerToggle: { $event: Event; active: string }) => {
      const layer: IpproMapLayer = props.layers.find((obj: IpproMapLayer) => obj.id === layerToggle.active);
      if (layer) {
        emit('switch-layer', layer);
      }
    }

    return { changeLayer }
  }
})
