import proj4 from "proj4";

proj4.defs(
  'EPSG:31370',
  '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.869,52.2978,-103.724,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs'
)

export enum ECoordinateType {
  lambert72 = '31370',
  wgs84= '4326',
  webMercator= '3857',
  lambert2008= '3812'
}

export interface ICoordinate {
  isNaN: boolean
  spatialReference?: { wkid: ECoordinateType }
  x: number
  y: number
}

export class CoordinateParserService {

  public parseCoordinate(keyword: string): ICoordinate {
    return this.parseWGS84(keyword) ?? this.parseLambert72(keyword)
  }

  public convertCoordinateToLambert72(coordinate: ICoordinate): number[] {
    let lambert72Coordinate: number[] = [coordinate.x, coordinate.y]
    if (coordinate.spatialReference?.wkid === ECoordinateType.wgs84) {
      lambert72Coordinate = proj4('EPSG:4326', 'EPSG:31370', [coordinate.y, coordinate.x])
    }
    if (coordinate.spatialReference?.wkid === ECoordinateType.webMercator) {
      lambert72Coordinate = proj4('EPSG:3857', 'EPSG:31370', [coordinate.y, coordinate.x])
    }
    return lambert72Coordinate
  }

  public formatCoordinate(coordinate: ICoordinate): string {
    if (coordinate.spatialReference?.wkid === ECoordinateType.wgs84) {
      return `${coordinate.x.toFixed(6).replace('.', ',')}° - ${coordinate.y.toFixed(6).replace('.', ',')}°`
    }
    if (coordinate.spatialReference?.wkid === ECoordinateType.lambert72) {
      return `${coordinate.x
        .toFixed(2)
        .replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')
        .replace('.', ',')} m - ${coordinate.y
        .toFixed(2)
        .replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ')
        .replace('.', ',')} m`
    }
    throw new Error('mapCoordinateToSelectionInput: unsupported wkid > ' + coordinate.spatialReference?.wkid)
  }

  public parseWGS84(keyword: string): ICoordinate {
    const coords: ICoordinate = this.parseValidGeographicalCoordinates(keyword)
    if (!coords.isNaN && this.isValidGeographicalCoordinatePair(keyword)) {
      return coords
    }
    return null
  }

  public parseLambert72(keyword: string): ICoordinate {
    const coords: ICoordinate = this.parseValidLamber72Coordinates(keyword)
    if (!coords.isNaN && this.isValidLambert72CoordinatePair(keyword)) {
      return coords
    }
    return null
  }

  public parseWebMercator(keyword: string): ICoordinate {
    const coords: ICoordinate = this.parseValidWebMercatorCoordinates(keyword)
    if (!coords.isNaN && this.isValidWebMercatorCoordinatePair(keyword)) {
      return coords
    }
    return null
  }

  public isValidLambert72CoordinatePair(string: string): boolean {
    try {
      const coord = this._parseFloatingPointNumber(string)
      if (coord.x > 17736 && coord.x < 297290 && coord.y > 23697 && coord.y < 245376) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  public isValidWebMercatorCoordinatePair(string: string): boolean {
    try {
      const coord = this._parseFloatingPointNumber(string)
      // TO VERIFY: extra check on coordinate min max ranges?
      // if (coord.x > 17736 && coord.x < 297290 && coord.y > 23697 && coord.y < 245376) {
      //   return true
      // }
      return true
    } catch (e) {
      return false
    }
  }

  public parseValidLamber72Coordinates(string: string): ICoordinate {
    try {
      const coord: ICoordinate = this._parseFloatingPointNumber(string)
      if (isNaN(coord.x)) {
        coord.isNaN = true
      } else if (coord.x > 17736 && coord.x < 297290) {
        coord.spatialReference = { wkid: ECoordinateType.lambert72 }
      } else {
        coord.isNaN = true
      }
      return coord
    } catch (e) {}
    return { x: NaN, y: NaN, isNaN: true }
  }

  public isValidGeographicalCoordinatePair(val: string): boolean {
    try {
      const coord = this._parseFloatingPointNumber(val)
      if (Math.abs(coord.x) <= 90 && Math.abs(coord.y) <= 180) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  }

  public parseValidGeographicalCoordinates(val: string): ICoordinate {
    try {
      const coord = this._parseFloatingPointNumber(val)
      if (isNaN(coord.x)) {
        coord.isNaN = true
      } else if (Math.abs(coord.x) <= 90) {
        coord.spatialReference = { wkid: ECoordinateType.wgs84 }
      } else {
        coord.isNaN = true
      }

      return coord
    } catch (e) {}
    return { x: NaN, y: NaN, isNaN: true }
  }

  public parseValidWebMercatorCoordinates(val: string): ICoordinate {
    try {
      const coord = this._parseFloatingPointNumber(val)
      if (isNaN(coord.x) || isNaN(coord.y)) {
        coord.isNaN = true
      } else {
        // TO VERIFY: extra check on coordinate min max ranges?
        coord.spatialReference = {wkid: ECoordinateType.webMercator}
      }
      return coord
    } catch (e) {}
    return { x: NaN, y: NaN, isNaN: true }
  }

  public tryParseWithCrs(val: string, crs: ECoordinateType): ICoordinate {
    if (crs === ECoordinateType.wgs84 && this.isValidGeographicalCoordinatePair(val)) {
      return this.parseWGS84(val)
    }
    if (crs === ECoordinateType.lambert72 && this.isValidLambert72CoordinatePair(val)) {
      return this.parseLambert72(val)
    }
    if (crs === ECoordinateType.webMercator && this.isValidWebMercatorCoordinatePair(val)) {
      return this.parseWebMercator(val)
    }
    // throw new Error(`parseWithCrs: crs '${crs}' not supported for coordinate '${val}'`)
    return null
  }

  private _parseFloatingPointNumber(string: string): ICoordinate {
    const searchString = this._removeSpecialCharacters(string)
    try {
      const separatorsToTry = [' ', '-', ',', ';', '.', '--']
      let coordinates: ICoordinate
      for (const separator of separatorsToTry) {
        coordinates = this._parseFloatingPointNumberWithSeparator(searchString, separator)
        if (!isNaN(coordinates.x) && !isNaN(coordinates.y)) {
          break
        }
      }
      return coordinates
    } catch (e) {
      return {
        x: Number.NaN,
        y: Number.NaN,
        isNaN: true
      }
    }
  }

  private _parseFloatingPointNumberWithSeparator(string: string, separator: string): ICoordinate {
    try {
      const values = string.split(separator)
      if (values.length > 2) {
        return {
          x: Number.NaN,
          y: Number.NaN,
          isNaN: true
        }
      }

      const x = this._parseCoordinate(values[0])
      const y = this._parseCoordinate(values[1])

      return {
        x: +x,
        y: y !== undefined ? +y : undefined,
        isNaN: false
      }
    } catch (e) {
      return {
        x: NaN,
        y: NaN,
        isNaN: true
      }
    }
  }

  private _parseCoordinate(coord: string): string {
    // remove all whitespace
    coord = coord.replace(/\s/g, '')
    coord = coord.trim()
    // remove all ','
    coord = coord.replace(/,/g, '.')
    const items = coord.split('.')
    if (items.length === 2) {
      if (items[0] === '' && items[1]) {
        coord = '0.' + items[1] // Add Leading zero if none is present
      }
      if (items[0] && items[1] === '') {
        coord = items[0] + '.0'
      }
    }
    return coord
  }

  // Remove °, m & km from search string
  private _removeSpecialCharacters(string: string): string {
    const charactersToRemove = ['°', 'm', 'km']
    for (const char of charactersToRemove) {
      const regexp = new RegExp(char, 'g')
      string = string.replace(regexp, '')
    }
    return string
  }
}

export const CoordinateParserServiceFactory = () => {
  return new CoordinateParserService()
}
