import { ValidationStep } from '@/api/klip-api.proxy';
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'KlSteps',
    props: {
        steps: {
            type: Array as () => Array<ValidationStep>,
            required: true,
        }
    },
    setup(props) {

        const amountOfMessages = (step: ValidationStep): string => {
            if (step.validationMessages && step.validationMessages.length > 0) {
                const countWarnings = step.validationMessages.filter(m => m.level === 'Warning').length;
                const countErrors = step.validationMessages.filter(m => m.level === 'Error').length;

                let amountString = '';

                if (countErrors === 1) {
                    amountString = '<span class="vl-u-text--bold vl-u-text--error">1 error</span>';
                    if (countWarnings > 0) {
                        amountString += ' & ';
                    }
                } else if (countErrors > 1) {
                    amountString = `<span class="vl-u-text--bold vl-u-text--error">${countErrors} errors</span>`;
                    if (countWarnings > 0) {
                        amountString += ' & ';
                    }
                }

                if (countWarnings === 1) {
                    amountString += '1 warning';
                } else if (countWarnings > 1) {
                    amountString += `${countWarnings} warnings`;
                }

                return amountString;

            }
            return '';
        }

        const accordion = (step: ValidationStep): any => {
            if (step.validationMessages && step.validationMessages.length > 0) {
                if (step.validationMessages.some(e => e.level === 'Error')) {
                    return 'open';
                }
                return true;
            }
            return false;
        }

        return {
            amountOfMessages,
            accordion,
        }
    }
})
